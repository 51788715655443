import * as React from 'react'

import Layout from '@components/layout'

const OurValuesPage = () => {
  return (
    <Layout pageTitle="Our Values">
      
    </Layout>
  )
}

export default OurValuesPage